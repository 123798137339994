:root {
  --ct-body-color: #6c757d;
  --ct-body-font-size: 0.9rem;
  --ct-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  --ct-card-box-shadow: var(--ct-box-shadow);
  --ct-text-muted: #2a333d;
  --layout-box-shadow-color: 0px 4px 4px rgba(0, 0, 0, 0.1);
  --layout-topbar-bg-color: #fff;
  --layout-menu-item: #fff;
  --layout-primary-system-color: #031daa;
}

body {
  font-size: var(--ct-body-font-size);
}

a {
  text-decoration: none !important;
}

.card {
  box-shadow: var(--ct-card-box-shadow);
}

.side-nav {
  background-color: #21242b;
  color: #fff;
  height: 100vh;
}

.side-nav > .side-nav-item {
  border-bottom: 1px solid #31343b !important;
}

.layout_sidebar {
  background: #21242b !important;
}

.addButton {
  margin-right: 7px;
}

.dripicons-plus:before {
  margin-bottom: 2px;
}

.max-width-none {
  max-width: none !important;
}

.section-area {
  border-bottom: 1px solid #dbdddf;
  margin-bottom: 1rem;
  .title-section {
    font-weight: 700;
    font-size: 26px;
  }
}

.label-none-margin {
  margin: 0px !important;
  color: var(--ct-form-label-color);
  font-size: 14px;
}

.detail-infor-size-text {
  font-size: 14px;
}

.detail-info {
  align-items: center;
}

.simplebar-content {
  .form_group .row {
    width: auto;
  }
}

.rightsidebar-content-height {
  .fit-width-label-container {
    .form_group_control {
      min-width: 250px;
    }
  }
}

body .p-datatable-wrapper::-webkit-scrollbar-thumb {
  background: #cccccc;
  border: 3px solid #fff !important;
}

.modal-footer > * {
  margin: 0 !important;
}

.p-datatable-table {
  .p-datatable-tbody {
    .p-datatable-emptymessage {
      justify-content: center;
      td {
        justify-content: center;
      }
    }
  }
}

.form-action {
  border-top: 1px solid #cccccc;
  padding-top: 24px;
  margin-top: 53px;
}

.p-datatable .p-datatable-tbody > tr > td {
  a:hover {
    text-decoration: underline !important;
  }
}

.hight-light-sort::after {
  color: var(--ct-primary);
}

.form-wrap-area {
  .col-sm-12 {
    padding: 0px !important;
  }
  .row {
    margin: auto !important;
  }
}

.form-check-input[type="checkbox"],
.form-check-input[type="radio"] {
  cursor: pointer;
}

.modal-title {
  display: flex;

  img {
    margin-right: 12px;
  }
}

.pre-text {
  font-size: 100%;
  font-family: var(--ct-body-font-family) !important;
  word-wrap: break-word;
  white-space: pre-wrap;
  margin-bottom: 0px;
}

.display-hover {
  display: none !important;
}

.p-datatable .p-datatable-tbody > tr:hover {
  .display-hover {
    display: inline-flex !important;
  }
}

.button-style {
  border-radius: 4px;
  width: auto;
  min-height: 24px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.primary-button {
  background-color: #605bff;
  border: 1px solid #605bff;
}

.danger-button {
  background-color: #c42c21;
  border: 1px solid #c42c21;
}

.success-button {
  background-color: #007831;
  border: 1px solid #007831;
}

.spin-style {
  display: flex;
  margin: 1.8px 0;
  justify-content: center;
  .avatar-sm {
    height: 18px;
    width: 18px;
  }
}

.loading-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
  background: #343a40;
  height: 100%;
  width: 100%;
  opacity: 0.5;
}

.wrap-all-step {
  padding: 70px 2px 70px 40px;
  overflow: hidden;
  margin-right: 40px;
}

.wrap-scroll-card {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-bottom: 0px !important;
}

.text-bold {
  font-weight: 700;
}

.spinner-border  {
  color: #605bff;
}

.display-content {
  display: contents;
}

.display-fit-content {
  width: fit-content;
}

.border-width {
  border-width: 1px;
}

.border-row-none .row {
  border: none !important;
}

//custom this when apply dark mode
.sidebar-search .search-icon {
  color: var(--layout-menu-item) !important;
}

.account-user-name {
  color: var(--ct-link-color) !important;
}

.min-height-none {
  min-height: 0px !important;
}

.sidebar .sidebar_header {
  background-color: var(--layout-primary-system-color) !important;
}
