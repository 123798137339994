.layout {
  .sidebar_header_text {
    padding: 0 25px;
  }

  &--collapsed {
    .sidebar_header {
      padding: 0;
    }
  }
}

@media screen and (min-width: 960px) {
  .page-content {
    padding: 31px 40px;
  }
}

.layout_content {
  .spacing-icon {
    padding-right: 16px;
    color: var(--ct-primary);
  }

  .dropdown {
    &-toggle {
      &.nav-user {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
        display: flex;
        align-items: center;
      }
    }
  }

  #dropdown-languages {
    &:after {
      display: none;
    }
  }
}

.left_sidebar_img_icon {
  margin: 0 10px 0 0;
  width: 14px !important;
}

body {
  font-family: var(--ct-body-font-family) !important;
  font-size: var(--ct-body-font-size) !important;
  font-weight: var(--ct-body-font-weight) !important;
  line-height: var(--ct-body-line-height) !important;
  color: var(--ct-body-color) !important;
  text-align: var(--ct-body-text-align) !important;
  background-color: var(--ct-body-bg) !important;
}

h2 {
  font-weight: 700 !important;
}

.layout-detail-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 140px);
}
